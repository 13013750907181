import * as d3 from "d3";
import { getNodeWeight } from "./utils/getNodeWeight";

export function initializeOrUpdateSimulation({
  simulationRef,
  nodes,
  links,
  rootId,
  initialNodeId,
  previousRootIdRef,
  ticked,
  measuredWidth,
  measuredHeight
}) {
  if (!simulationRef.current) {
    simulationRef.current = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3.forceLink(links)
          .id((d) => d.id)
          .distance(0)
          .strength(0.7)
      )
      .force(
        "charge",
        d3.forceManyBody().strength((d) => {
          const w = getNodeWeight(d);
          return -500 * w;
        })
      )
      .force(
        "collision",
        d3.forceCollide().radius((d) => {
          const w = getNodeWeight(d);
          if (d.id === initialNodeId) return 60; // Initial node is larger
          return d.id === rootId ? 50 : 40 + 30 / w;
        })
      )
      .alphaDecay(0.07)
      .alphaTarget(0.1)
      .on("tick", ticked);
  } else {
    simulationRef.current.nodes(nodes);
    simulationRef.current.force("link").links(links);
    simulationRef.current.alpha(0.3).restart();
  }

  // Initial node always fixed at the center
  const initialNode = nodes.find((n) => n.id === initialNodeId);
  if (initialNode) {
    initialNode.fx = measuredWidth / 2;
    initialNode.fy = measuredHeight / 2;
  }

  const previousRootId = previousRootIdRef.current;
  if (previousRootId !== rootId) {
    // Root node changed
    // Release old root node if it's not the initial node
    if (previousRootId && previousRootId !== initialNodeId) {
      const oldRootNode = nodes.find((n) => n.id === previousRootId);
      if (oldRootNode) {
        oldRootNode.fx = null;
        oldRootNode.fy = null;
      }
    }

    // Fix the new root node at its current position
    const newRootNode = nodes.find((n) => n.id === rootId);
    if (newRootNode) {
      // Lock it at current coordinates
      newRootNode.fx = newRootNode.x;
      newRootNode.fy = newRootNode.y;
    }

    // Update previous root ID
    previousRootIdRef.current = rootId;
  } else {
    // Root node unchanged, ensure it remains fixed where it was.
    const currentRootNode = nodes.find((n) => n.id === rootId);
    if (currentRootNode && currentRootNode.id !== initialNodeId) {
      // Keep it fixed where it is
      currentRootNode.fx = currentRootNode.fx ?? currentRootNode.x;
      currentRootNode.fy = currentRootNode.fy ?? currentRootNode.y;
    }
  }
}
