// src/components/LandingPage/LandingPage.js

import React from 'react';
import logo from '../../assets/images/AethericAI.png';

const LandingPage = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
      <img
        src={logo}
        alt="AethericAI Logo"
        className="w-1/2 h-1/2 lg:w-1/4 lg:h-1/4"
      />
    </div>
  );
};

export default LandingPage;
