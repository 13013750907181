// src/components/Home.js

import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import logo from '../../assets/images/AethericAI.png';
import LoginModal from '../Auth/LoginModal';

const Home = () => {
  const { showLoginModal } = useContext(AuthContext);

  return (
    <div className="flex justify-center items-center bg-black flex-grow relative">
      <img
        src={logo}
        alt="AethericAI Logo"
        className="w-1/2 h-1/2 lg:w-1/4 lg:h-1/4"
      />
      {showLoginModal && <LoginModal />}
    </div>
  );
};

export default Home;
