import { useCallback, useRef } from "react";

const useHandleNodeEvents = ({
  actionPanelOpen,
  setActionPanelOpen,
  panelOpenNode,
  setPanelOpenNode,
  selectedNodes,
  setSelectedNodes,
  fetchNodeAndRelationships, // renamed for clarity and generality
}) => {
  const clickTimeout = useRef(null);

  const handleNodeClick = useCallback(
    (node) => {
      // Clear any pending single-click timeout
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      // Set a new timeout for single-click logic
      clickTimeout.current = setTimeout(() => {
        if (!node.id) {
          // Node without an ID (e.g., newly created or special case)
          if (actionPanelOpen) {
            // Close the panel if open
            setActionPanelOpen(false);
            setPanelOpenNode(null);
          } else {
            // Open the panel if closed
            setActionPanelOpen(true);
            setPanelOpenNode(node);
          }
        } else {
          // Node with an ID
          if (!node.expanded) {
            // Fetch related nodes if not expanded yet
            // Assuming fetchNodeAndRelationships accepts { id, depth } as arguments
            fetchNodeAndRelationships({ id: node.id, depth: 2 });
            node.expanded = true;
          }
        }

        // Clear the click timeout
        clickTimeout.current = null;
      }, 200);
    },
    [
      actionPanelOpen,
      setActionPanelOpen,
      setPanelOpenNode,
      fetchNodeAndRelationships,
    ]
  );

  const handleNodeDoubleClick = useCallback(
    (node) => {
      // If double-click detected, clear single-click timeout
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      if (!node.id) {
        console.debug("Double-clicked node without ID:", node);
        return;
      }

      setSelectedNodes((currentSelected) => {
        const isSelected = currentSelected.some(
          (selectedNode) => selectedNode.id === node.id
        );
        let newSelected = [...currentSelected];

        if (isSelected) {
          // If it's already selected
          if (!actionPanelOpen) {
            // If panel closed, open panel and set this node
            setPanelOpenNode(node);
            setActionPanelOpen(true);
          } else {
            // If panel open, deselect this node and close panel
            newSelected = currentSelected.filter((n) => n.id !== node.id);
            setActionPanelOpen(false);
            setPanelOpenNode(null);
          }
        } else {
          // Not selected yet, select and open panel
          newSelected.push(node);
          setActionPanelOpen(true);
          setPanelOpenNode(node);
        }

        return newSelected;
      });
    },
    [actionPanelOpen, setActionPanelOpen, setPanelOpenNode, setSelectedNodes]
  );

  return { handleNodeClick, handleNodeDoubleClick };
};

export default useHandleNodeEvents;
