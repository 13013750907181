// src/components/AethericAI/DevGraphNavigator/NewGraphNavigator.js

import React, { useState, useEffect, useRef } from "react";
import { InitialRootProvider } from "../../../contexts/InitialRootContext";
import { useGraphData } from "./useGraphData";
import { useD3Graph } from "./useD3Graph";
import { ActionPanel } from "./ActionPanel";
import useHandleNodeEvents from "./handleNodeEvents";
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_NODE_AND_RELATIONSHIPS,
  GET_GRAPH
} from '../../../graphql/Graph/graph_queries';
import {
  CREATE_NODE,
  CREATE_RELATIONSHIP
} from '../../../graphql/Graph/graph_mutations';

const NewGraphNavigator = () => {
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [actionPanelOpen, setActionPanelOpen] = useState(false);

  // Set initial root node with a known id
  const [rootNode, setRootNode] = useState({ id: "a0de03b3-b027-43e5-a411-0ccfe7820613", name: 'AethericAI' });
  const initialRootNodeRef = useRef(rootNode);
  const [panelOpenNode, setPanelOpenNode] = useState(null);

  // Use the hook to manage graph data retrieval
  const { graphData, fetchNodeAndRelationships } = useGraphData(rootNode, { query: GET_NODE_AND_RELATIONSHIPS });

  const { handleNodeClick, handleNodeDoubleClick } = useHandleNodeEvents({
    actionPanelOpen,
    setActionPanelOpen,
    panelOpenNode,
    setPanelOpenNode,
    selectedNodes,
    setSelectedNodes,
    fetchNodeAndRelationships,
  });

  const { svgRef, zoomToNode } = useD3Graph({
    graphData,
    initialNode: initialRootNodeRef.current,
    rootNode,
    selectedNodes,
    panelOpenNodeId: panelOpenNode ? panelOpenNode.id : null,
    onNodeClick: handleNodeClick,
    onNodeDoubleClick: handleNodeDoubleClick,
  });

  // After graphData updates, if we have the root node in the returned data, update rootNode state
  useEffect(() => {
    const foundRoot = graphData.nodes.find((node) => node.id === rootNode.id);
    if (foundRoot) {
      // Update the entire rootNode object with all details of foundRoot
      // This includes properties, name, etc.
      setRootNode(foundRoot);
    }
  }, [graphData, rootNode.id]);

  useEffect(() => {
    console.log("GraphData updated:", graphData);
    const foundRoot = graphData.nodes.find((node) => node.id === rootNode.id);
    console.log("Looking for root:", rootNode.id, "Found:", foundRoot);
    if (foundRoot) {
      setRootNode(foundRoot);
      console.log("Updated rootNode:", foundRoot);
    }
  }, [graphData, rootNode.id]);

  useEffect(() => {
    if (panelOpenNode) {
      zoomToNode(panelOpenNode.id, 1.01);
    }
  }, [panelOpenNode, zoomToNode]);

  const handleSetRoot = (node) => {
    if (node) {
      if (node.id) {
        setRootNode({ ...node, id: String(node.id) });
      } else {
        setRootNode({ id: "", name: node.name || "Unnamed Node" });
      }
    } else {
      setRootNode({ id: "", name: "Unknown Node" });
    }
  };

  const { refetch: refetchNodeAndRelationships } = useQuery(GET_NODE_AND_RELATIONSHIPS, {
    variables: { id: rootNode.id, depth: 2 },
    skip: true
  });

  const { refetch: refetchEntireGraph } = useQuery(GET_GRAPH, {
    skip: true
  });

  const [createNode] = useMutation(CREATE_NODE);
  const [createRelationship] = useMutation(CREATE_RELATIONSHIP);

  const handleFetchNodeAndRelationships = async () => {
    try {
      const { data } = await refetchNodeAndRelationships({
        id: rootNode.id && rootNode.id.trim() !== "" ? rootNode.id : undefined,
        name: (!rootNode.id || rootNode.id.trim() === "") ? rootNode.name : undefined,
        depth: 1
      });
      console.log('Node and Relationships data:', data);
    } catch (error) {
      console.error('Error fetching node and relationships:', error);
    }
  };

  const handleFetchEntireGraph = async () => {
    try {
      const { data } = await refetchEntireGraph();
      console.log('Entire Graph data:', data);
    } catch (error) {
      console.error('Error fetching entire graph:', error);
    }
  };

  const handleCreateNode = async () => {
    try {
      const { data } = await createNode({
        variables: {
          input: {
            label: "CONCEPT",
            description: "New concept from frontend",
            tags: ["IMPORTANT"],
            privacy_status: "PUBLIC",
            properties: { createdBy: "NewGraphNavigator" }
          }
        }
      });
      console.log('Node created:', data.createNode);
    } catch (error) {
      console.error('Error creating node:', error);
    }
  };

  const handleCreateRelationship = async () => {
    try {
      const { data } = await createRelationship({
        variables: {
          input: {
            type: "RELATED_TO",
            source: "node-id-1",
            target: "node-id-2",
            properties: { strength: 10 }
          }
        }
      });
      console.log('Relationship created:', data.createRelationship);
    } catch (error) {
      console.error('Error creating relationship:', error);
    }
  };

  return (
    <InitialRootProvider initialRoot={initialRootNodeRef.current}>
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>
        {actionPanelOpen && (
          <ActionPanel
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            onSetRoot={handleSetRoot}
            rootNode={rootNode}
            panelOpenNode={panelOpenNode}
            setPanelOpenNode={setPanelOpenNode}
            actionPanelOpen={actionPanelOpen}
            setActionPanelOpen={setActionPanelOpen}
          />
        )}
        <svg
          ref={svgRef}
          style={{ width: "100%", height: "100%" }}
        />
        {/* <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          background: 'rgba(0,0,0,0.5)',
          color: 'white',
          padding: '10px',
          borderRadius: '8px'
        }}>
          <h3>Graph Controls</h3>
          <button onClick={handleFetchNodeAndRelationships} style={{ display: 'block', marginBottom: '10px' }}>
            Fetch Node & Relationships (By Root Node)
          </button>
          <button onClick={handleFetchEntireGraph} style={{ display: 'block', marginBottom: '10px' }}>
            Fetch Entire Graph
          </button>
          <button onClick={handleCreateNode} style={{ display: 'block', marginBottom: '10px' }}>
            Create New Node
          </button>
          <button onClick={handleCreateRelationship} style={{ display: 'block', marginBottom: '10px' }}>
            Create New Relationship
          </button>
        </div> */}
      </div>
    </InitialRootProvider>
  );
};

export default NewGraphNavigator;
