// src/components/UserComponents/ListUser.js

import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { LIST_USERS_QUERY } from '../../graphql/User/user_queries';
import UserTable from './UserTable';
import { Button } from '../UI_temp';

const ListUser = ({ handleEdit, handleDelete, setActiveView }) => {
  const client = useApolloClient();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await client.query({
        query: LIST_USERS_QUERY,
        variables: { offset: 0, limit: 10 },
        fetchPolicy: 'no-cache',
      });
      setUsers(data.listUsers);
    } catch (err) {
      console.error('Error loading users:', err);
      setError('Failed to load users. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button variant="success" onClick={() => setActiveView('create')} className="mb-4">
        Add New User
      </Button>
      {loading && <p>Loading users...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && (
        <UserTable users={users} handleEdit={handleEdit} handleDelete={handleDelete} />
      )}
    </div>
  );
};

export default ListUser;