// src/components/GraphNavigator/hierarchyHelpers.js

import * as d3 from 'd3';

/**
 * Converts flat node and link data into a hierarchical structure.
 *
 * @param {Array} nodes - Array of node objects.
 * @param {Array} links - Array of link objects.
 * @param {string} rootNodeId - ID of the root node.
 * @returns {d3.HierarchyNode} Hierarchical node.
 */
export const createHierarchy = (nodes, links, rootNodeId) => {
  // Create a map for quick lookup of parent IDs
  const parentMap = {};
  links.forEach(link => {
    parentMap[link.target] = link.source;
  });

  // Use d3.stratify to create the hierarchy
  const stratify = d3.stratify()
    .id(d => d.id)
    .parentId(d => parentMap[d.id]);

  const root = stratify(nodes);

  return root;
};

/**
 * Computes initial positions for nodes using d3.treemap.
 *
 * @param {d3.HierarchyNode} root - Hierarchical root node.
 * @param {number} width - Width of the SVG container.
 * @param {number} height - Height of the SVG container.
 * @returns {Array} Nodes with x and y positions.
 */
export const computeInitialPositions = (root, width, height) => {
  const treemapLayout = d3.treemap()
    .size([width, height])
    .padding(10)
    .round(true);

  treemapLayout(root);

  const nodesWithPositions = root.descendants().map(d => ({
    ...d.data,
    x: d.x0 + (d.x1 - d.x0) / 2, // Center x
    y: d.y0 + (d.y1 - d.y0) / 2, // Center y
  }));

  return nodesWithPositions;
};

/**
 * Fixes the root node's position at the center.
 *
 * @param {Array} nodes - Array of node objects with positions.
 * @param {number} width - Width of the SVG container.
 * @param {number} height - Height of the SVG container.
 * @param {string} rootNodeId - ID of the root node.
 */
export const fixRootNode = (nodes, width, height, rootNodeId) => {
  const rootNode = nodes.find(node => node.id === rootNodeId);
  if (rootNode) {
    rootNode.x = width / 2;
    rootNode.y = height / 2;
    rootNode.fx = width / 2;
    rootNode.fy = height / 2;
    console.log(`Root node ${rootNode.id} fixed at (${rootNode.fx}, ${rootNode.fy})`);
  } else {
    console.error(`Root node with ID ${rootNodeId} not found`);
  }
};
