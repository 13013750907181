// src/components/GraphNavigator/renderNodes.js

import * as d3 from 'd3';
import { wrapText } from './wrapText';

const renderNodes = (svg, nodes, dragstarted, dragged, dragended, rootNodeId) => {
  const nodeRadius = 30;

  const node = svg
    .append('g')
    .attr('class', 'nodes')
    .selectAll('g')
    .data(nodes)
    .enter()
    .append('g')
    .call(
      d3
        .drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended)
    );

  node
    .append('circle')
    .attr('r', d => (d.id === rootNodeId ? nodeRadius * 1.5 : nodeRadius)) // Larger radius for root
    .attr('fill', d => ('#000000')) // Different color for root
    .attr('stroke', '#ffffff')
    .attr('stroke-width', 2);

  node.each(function (d) {
    const maxTextWidth = nodeRadius * 1.8;
    // console.log("name in renderNodes:", d.name);
    const lines = wrapText(d.name, maxTextWidth);

    const textElement = d3
      .select(this)
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .style('fill', '#ffffff')
      .style('font-size', '12px');

    const totalHeight = lines.length * 14;
    const startY = -totalHeight / 2 + 7;

    lines.forEach((line) => {
      textElement
        .append('tspan')
        .attr('x', 0)
        .attr('y', startY + line.y)
        .text(line.text);
    });
  });

  return node;
};

export default renderNodes;
