// src/components/GraphNavigator/graphDataProcessor.js

import { customNodeMapper, customLinkMapper } from '../customMappers';
import { degreeCentrality } from './centralities'; 
import { calculateRelationshipCounts, updateRelationshipCounts } from './relationshipCounts';
import { mapNode, mapLink } from './dataMappers';

/**
 * Processes raw GraphQL data into a format suitable for D3.js visualization.
 *
 * @param {Object} data - The raw data object containing nodes and relationships.
 * @param {Array<Object>} data.nodes - The array of node objects.
 * @param {Array<Object>} data.relationships - The array of relationship objects.
 * @param {Object} [options] - Optional configurations.
 * @param {string} [options.nodeNameField='name'] - The property to use as the node's display name.
 * @param {string} [options.nodeTypeField='label'] - The property to use as the node's type.
 * @param {Function} [options.customNodeMapper] - A custom function to map node properties.
 * @param {Function} [options.customLinkMapper] - A custom function to map relationship properties.
 * @returns {Object} An object containing processed nodes and links.
 */
export const processDataForGraph = (
  { nodes = [], relationships = [] },
  {
    nodeNameField = 'name',
    nodeTypeField = 'label',
    customNodeMapper,
    customLinkMapper,
  } = {}
) => {
  const relationshipCounts = calculateRelationshipCounts(nodes, relationships);
  updateRelationshipCounts(relationshipCounts, relationships);

  const centrality = degreeCentrality(nodes, relationships);

  const processedNodes = nodes.map(node => mapNode(node, relationshipCounts, centrality, { nodeNameField, nodeTypeField, customNodeMapper }));
  const processedLinks = relationships.map(rel => mapLink(rel, { customLinkMapper }));

  console.log('processedNodes', processedNodes);
  console.log('processedLinks', processedLinks);

  return { nodes: processedNodes, links: processedLinks };
};