// src/components/GraphNavigator/DataProcessor/centralities.js

/**
 * Calculates degree centrality for each node.
 *
 * @param {Array<Object>} nodes - The array of node objects.
 * @param {Array<Object>} links - The array of relationship objects.
 * 
 * @returns {Object} A map of node IDs to their degree centrality.
 */
export const degreeCentrality = (nodes, links) => {
  if (!nodes || !links || nodes.length === 0 || links.length === 0) {
    throw new Error("Invalid input data for degree centrality calculation.");
  }

  // Initialize a map to store the degree centrality of each node.
  const centralityMap = nodes.reduce((map, node) => {
    map[node.id] = 0;
    return map;
  }, {});

  // Iterate through each link to calculate degree centrality.
  links.forEach((link) => {
    // Increment the centrality of the source node.
    if (centralityMap[link.source] !== undefined) {
      centralityMap[link.source] += 1;
    }
    // Increment the centrality of the target node.
    if (centralityMap[link.target] !== undefined) {
      centralityMap[link.target] += 1;
    }
  });

  return centralityMap;
};

