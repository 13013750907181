// src/graphql/User/user_queries.js

import { gql } from '@apollo/client';
import { USER_FIELDS } from './user_mutations'; // Import the fragment

export const ME_QUERY = gql`
  query Me {
    me {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const GET_USER_BY_ID_QUERY = gql`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const LIST_USERS_QUERY = gql`
  query ListUsers($offset: Int, $limit: Int) {
    listUsers(offset: $offset, limit: $limit) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;
