// src/apolloClient.js

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { from } from '@apollo/client';

// Determine the API URL based on the environment
const api_url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/graphql' // Development API URL
    : 'https://aethericai.onrender.com/graphql'; // Production API URL

// Create an HTTP link to your GraphQL server
const httpLink = createHttpLink({
  uri: api_url,
});

// Use setContext to add the Authorization header with the token from localStorage
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Create an error link to handle GraphQL errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Iterate over errors
    for (let err of graphQLErrors) {
      // Check if error code indicates an invalid or expired token
      if (err.extensions?.code === 'UNAUTHENTICATED') {
        // We can’t directly call logout here since this is not a React component.
        // Instead, we can store a flag and handle it outside or directly manipulate localStorage.
        
        // Clear local storage to mimic logout
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        // Set a flag in localStorage to show the login modal on the home page
        localStorage.setItem('showLoginModal', 'true');
        
        // Redirect to the home page
        window.location.href = '/';
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
