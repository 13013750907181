// src/components/AethericAI/DevGraphNavigator/graph/zoomToNode.js

import * as d3 from "d3";

export function zoomToNode({ svgRef, graphData, nodeId, width, height, scaleFactor = 1.5 }) {
  if (!graphData?.nodes || !svgRef.current) return;
  const nodeData = graphData.nodes.find((d) => d.id === nodeId);
  if (!nodeData) return;

  const { x, y } = nodeData;
  const svg = d3.select(svgRef.current);
  const transform = d3.zoomTransform(svg.node());

  let measuredWidth = width;
  let measuredHeight = height;
  if (svgRef.current) {
    const rect = svgRef.current.getBoundingClientRect();
    measuredWidth = rect.width;
    measuredHeight = rect.height;
  }

  const newX = measuredWidth / 2 - x * transform.k;
  const newY = measuredHeight / 2 - y * transform.k;

  svg.transition().duration(750).call(
    d3.zoom().transform,
    d3.zoomIdentity.translate(newX, newY).scale(scaleFactor)
  );
}
