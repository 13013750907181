// src/components/AuthLoader.js

import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../../graphql/User/user_queries';
import AuthContext from '../../contexts/AuthContext';

const AuthLoader = ({ children }) => {
  const { authToken, user, updateUser, logout } = useContext(AuthContext);
  const { loading, error, data } = useQuery(ME_QUERY, {
    skip: !authToken || user, // Skip if no token or user data already exists
  });

  useEffect(() => {
    if (data && data.me) {
      updateUser(data.me);
    }
    if (error) {
      console.error('Error fetching user data:', error);
      // If token is invalid or expired, log out the user
      logout();
    }
  }, [data, error, updateUser, logout]);

  if (loading) return <p>Loading user data...</p>;

  return <>{children}</>;
};

export default AuthLoader;