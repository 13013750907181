// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { ME_QUERY } from '../graphql/User/user_queries';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  
  // Add state for the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);

  const client = useApolloClient();

  const updateUser = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const login = (token, userData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setAuthToken(token);
    setUser(userData);
    // Hide modal once user logs in
    setShowLoginModal(false);
    localStorage.removeItem('showLoginModal');
  };

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthToken(null);
    setUser(null);
    client.clearStore();
    // If logging out manually, you could setShowLoginModal(true) if you want.
  }, [client]);

  useEffect(() => {
    if (authToken && !user) {
      const fetchUserData = async () => {
        try {
          const { data } = await client.query({
            query: ME_QUERY,
          });
          if (data && data.me) {
            updateUser(data.me);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout(); // Log out if there's an error (e.g., token is invalid)
        }
      };
      fetchUserData();
    }
  }, [authToken, user, client, logout]);

  // On mount, check if we should show the login modal
  useEffect(() => {
    const shouldShowModal = localStorage.getItem('showLoginModal');
    if (shouldShowModal === 'true') {
      setShowLoginModal(true);
      // Remove the flag since we only want to show it once
      localStorage.removeItem('showLoginModal');
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout, updateUser, showLoginModal, setShowLoginModal }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
