// src/components/dashboard.js

import React, { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // If user is not authenticated, redirect to login page
      navigate('/');
    }
  }, [user, navigate]);

  if (!user) {
    return null; // Or a loading indicator
  }

  return (
    <div className="flex justify-center items-center bg-black flex-grow">
      <div className="dashboard">
        <h1>Welcome, {user.username}!</h1>
        <p>Email: {user.email}</p>
        <p>Role: {user.role}</p>
      </div>
    </div>
  );
};

export default Dashboard;