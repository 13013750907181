// src/components/Auth/LoginModal.js

import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import { LOGIN_MUTATION } from '../../graphql/User/user_mutations';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import the icons

function LoginModal({ onClose }) {
  const [email, setEmail] = useState(''); // Changed from username to email
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const { login } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize the navigate function

  const [loginMutation, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      login(data.login.token, data.login.user); // Log in the user
      onClose(); // Close the modal
      navigate('/aethericai'); // Navigate to the GraphNavigator
    },
    onError: (error) => {
      console.error("Login error:", error); // Log the error for debugging
      // The `error` object will trigger the `error` variable in the mutation state
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    loginMutation({ variables: { email, password } });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg relative w-80">
        <button
          className="absolute top-2 right-2 text-gray-300 hover:text-white text-xl font-bold"
          onClick={onClose}
          aria-label="Close Modal"
        >
          &times;
        </button>
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4 mt-4">
          <h2 className="text-2xl font-semibold text-center">Login</h2>
          
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
          />
          
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"} // Toggle input type
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-white pr-10" // Added padding to accommodate the button
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 hover:text-white focus:outline-none"
              aria-label={showPassword ? "Hide Password" : "Show Password"}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Using icons for better UX */}
            </button>
          </div>
          
          <button
            type="submit"
            disabled={loading}
            className={`px-4 py-2 rounded text-white ${
              loading
                ? 'bg-blue-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            } transition`}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
          
          {error && (
            <p className="text-red-500 text-sm text-center">
              Error: {error.message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default LoginModal;
