// src/components/AethericAI/DevGraphNavigator/ActionPanel.js

import React, { useContext } from "react";
import InitialRootContext from "../../../contexts/InitialRootContext";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid"; // Ensure Heroicons is installed

export const ActionPanel = ({ 
  selectedNodes,
  setSelectedNodes, 
  onSetRoot, 
  rootNode, 
  panelOpenNode,
  setPanelOpenNode, 
  actionPanelOpen,
  setActionPanelOpen 
}) => {
  const initialRootNode = useContext(InitialRootContext);

  if (!actionPanelOpen) {
    return null;
  } else {

    // Handler to set a node as the open panel node
    const handleSelectNode = (node) => {
      setPanelOpenNode(node);
    };

    // Handler to remove a node from selectedNodes
    const handleRemoveNode = (node) => {
      setSelectedNodes((currentSelected) =>
        currentSelected.filter((n) => n.id !== node.id)
      );
      if (selectedNodes.length === 1) { 
        setPanelOpenNode(null);
        setActionPanelOpen(false);
      }
    };

    // Handler to close the panel
    const handleClose = () => {
      setActionPanelOpen(false);
      setPanelOpenNode(null);
    };

    // Define animation variants
    const variants = {
      hidden: { opacity: 0, y: 50 },
      visible: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 50 }
    };

    return (
      <motion.div
        className="
          action-panel 
          bg-black text-white p-4 border border-gray-300 rounded-md z-50 
          w-full sm:w-64 md:w-72 lg:w-80
          shadow-lg
          fixed 
          bottom-0 left-0 right-0 sm:bottom-10 sm:right-10 sm:left-auto
          cursor-default select-none
          max-h-1/2 sm:max-h-full
          overflow-y-auto
        "
        drag
        dragHandle=".handle"
        dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }} // Allows free dragging within the viewport
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        role="dialog"
        aria-modal="true"
        aria-labelledby="action-panel-title"
      >
        {/* Header with Drag Handle and Close Button */}
        <div className="flex justify-between items-center mb-4 handle cursor-move">
          {/* Drag Handle */}
          <p className="text-lg font-medium">
            <button 
              onClick={() => onSetRoot(initialRootNode)} 
              className="hover:underline focus:outline-none"
            >
              {initialRootNode.name}
            </button>
          </p>
          {/* Close Button */}
          <button 
            onClick={handleClose} 
            className="text-gray-400 hover:text-white focus:outline-none"
            aria-label="Close Action Panel"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        {/* Root Node Button */}
        <div className="mb-4">
          <button 
            onClick={() => onSetRoot(panelOpenNode)} 
            className="w-full text-left mb-2 bg-gray-800 hover:bg-gray-700 p-2 rounded"
            disabled={!actionPanelOpen} 
          >
            {actionPanelOpen && panelOpenNode ? panelOpenNode.name : "None Opened"}
          </button>
        </div>

        {/* Divider */}
        <hr className="border-gray-700 mb-4" />

        {/* Selected Nodes Section */}
        <div className="selected-nodes-section">
          <h3 id="action-panel-title" className="text-lg font-semibold mb-2">Selected Nodes:</h3>
          {selectedNodes.length === 0 ? (
            <p className="text-sm text-gray-400">No nodes selected.</p>
          ) : (
            <ul className="space-y-2 max-h-48 overflow-y-auto">
              {selectedNodes.map((node) => (
                <li
                  key={node.id}
                  className="p-2 rounded cursor-pointer hover:bg-gray-700 flex justify-between items-center"
                  onClick={() => handleSelectNode(node)}
                  onDoubleClick={() => handleRemoveNode(node)}
                  title="Click to open | Double-click to remove"
                >
                  <span>{node.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </motion.div>
    );
  }
};
