// src/components/UserComponents/UserAdmin.js

import React, { useState } from 'react';
import {useMutation } from '@apollo/client';
import {
  DELETE_USER_MUTATION,
} from '../../graphql/User/user_mutations';
import ListUser from '../UserManagement/ListUser';
import CreateUser from '../UserManagement/CreateUser';
import UpdateUser from '../UserManagement/UpdateUser';
import { Button } from '../UI_temp';

const UserAdmin = () => {
  // const client = useApolloClient();

  const [activeView, setActiveView] = useState('list'); // 'list', 'create', 'edit'
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for mutations
  const [error, setError] = useState(null); // Error state for mutations

  // Define delete mutation
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);

  const refreshUserList = () => {
    // This function can be passed down to child components if needed
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setActiveView('edit');
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      setLoading(true);
      setError(null);

      try {
        await deleteUser({
          variables: { id: userId },
        });
        // Optionally, refresh the user list
      } catch (err) {
        console.error('Error deleting user:', err);
        setError('Failed to delete user. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-4">User Administration</h1>

      {activeView === 'list' && (
        <div className="overflow-x-auto">
          <ListUser
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            setActiveView={setActiveView}
          />
        </div>
      )}

      {activeView === 'create' && (
        <CreateUser
          setActiveView={setActiveView}
          refreshUserList={refreshUserList}
        />
      )}

      {activeView === 'edit' && selectedUser && (
        <UpdateUser
          selectedUser={selectedUser}
          setActiveView={setActiveView}
          refreshUserList={refreshUserList}
        />
      )}

      {error && <p className="text-red-500 mt-2">{error}</p>}
      {loading && <p>Processing...</p>}

      {activeView !== 'list' && (
        <Button variant="secondary" onClick={() => setActiveView('list')} className="mt-4">
          Back to User List
        </Button>
      )}
    </div>
  );
};

export default UserAdmin;