// src/components/AethericAI/DevGraphNavigator/graph/updateLinks.js

export function updateLinks(linksLayer, linksData) {
    const link = linksLayer.selectAll(".link").data(linksData, (d) => d.id);
  
    // Exit old links
    link.exit().remove();
  
    // Enter new links
    const linkEnter = link
      .enter()
      .append("line")
      .attr("class", "link")
      .attr("stroke", "#999")
      .attr("stroke-width", 1.5);
  
    // Merge and update all links
    linkEnter.merge(link).attr("stroke", "#999").attr("stroke-width", 1.5);
  }
  