// src/components/GraphNavigator/DataProcessor/dataMappers.js

/**
 * Maps a node object to a processed node object with additional properties.
 *
 * @param {Object} node - The original node object.
 * @param {Object} relationshipCounts - An object mapping node IDs to relationship counts.
 * @param {Object} centrality - An object mapping node IDs to centrality scores.
 * @param {Object} [options] - Optional configurations.
 * @param {string} [options.nodeNameField='name'] - The property to use as the node's display name.
 * @param {string} [options.nodeTypeField='label'] - The property to use as the node's type.
 * @param {Function} [options.customNodeMapper] - A custom function to map node properties.
 * @returns {Object} The processed node object.
 */
export const mapNode = (node, relationshipCounts, centrality, {
    nodeNameField = 'name',
    nodeTypeField = 'label',
    customNodeMapper,
  } = {}) => {
    if (customNodeMapper) {
      return customNodeMapper(node, relationshipCounts, centrality);
    }
  
    const name = node.properties[nodeNameField] || node.properties.username || 'No Name';
    const type = node[nodeTypeField] || node.label || 'Unknown';
  
    const weight = relationshipCounts[node.id] || 1;
    const nodeCentrality = centrality[node.id] || 0;
  
    return {
      ...node,
      name,
      type,
      weight,
      centrality: nodeCentrality, 
    };
  };
  
  /**
   * Maps a relationship object to a processed link object with additional properties.
   *
   * @param {Object} rel - The original relationship object.
   * @param {Function} [customLinkMapper] - A custom function to map relationship properties.
   * @returns {Object} The processed link object.
   */
  export const mapLink = (rel, { customLinkMapper } = {}) => {
    if (customLinkMapper) {
      return customLinkMapper(rel);
    }
  
    const distance = rel.distance || 1; // Default to 1 if distance is undefined
    const weight = 1 / distance; // Compute weight as inverse of distance
  
    return {
      ...rel,
      source: rel.source,
      target: rel.target,
      type: rel.type,
      properties: rel.properties,
      distance,
      weight,
    };
  };