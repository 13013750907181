// src/components/GraphNavigator/DataProcessor/relationshipCounts.js

/**
 * Calculates the number of relationships associated with each node.
 *
 * @param {Array<Object>} nodes - The array of node objects.
 * @param {Array<Object>} relationships - The array of relationship objects.
 * @returns {Object} An object mapping node IDs to relationship counts.
 */
export const calculateRelationshipCounts = (nodes, relationships) => {
    return nodes.reduce((acc, node) => {
      acc[node.id] = 0;
      return acc;
    }, {});
  };

  /**
 * Updates the relationship counts based on the relationships.
 *
 * @param {Object} relationshipCounts - An object mapping node IDs to relationship counts.
 * @param {Array<Object>} relationships - The array of relationship objects.
 */
export const updateRelationshipCounts = (relationshipCounts, relationships) => {
  relationships.forEach((rel) => {
    if (relationshipCounts[rel.source] !== undefined) {
      relationshipCounts[rel.source] += 1;
    }
    if (relationshipCounts[rel.target] !== undefined) {
      relationshipCounts[rel.target] += 1;
    }
  });
};