// src/components/GraphNavigator/dragHandlers.js

const dragHandlers = (simulation, rootNodeId, width, height) => {
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
  
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
  
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
  
      // If the dragged node is the root node, re-fix its position
      if (d.id === rootNodeId) { 
        d.fx = d.x;
        d.fy = d.y;
      } else {
        // For other nodes, release the fixed position
        d.fx = null; 
        d.fy = null;
      }
    }
  
  
    return { dragstarted, dragged, dragended };
  };
  
  export default dragHandlers;
  