import { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { computeGraphMetrics } from "./computeGraphMetrics";

export const useGraphData = (initialNode, { query }) => {
  const [graphData, setGraphData] = useState({
    nodes: [
      {
        id: String(initialNode.id),
        name: initialNode.name,
        x: 400,
        y: 300,
      },
    ],
    links: [],
  });

  const graphDataRef = useRef(graphData);
  const rootNodeRef = useRef(initialNode);
  const uniqueNewNodesRef = useRef([]);
  const uniqueNewLinksRef = useRef([]);
  const originNodeRef = useRef(null);

  // Generic lazy query based on passed-in query
  const [getNodeAndRelationships, { data }] = useLazyQuery(query);

  // Fetch function
  const fetchNodeAndRelationships = ({ id, name, depth = 1, originNodeId = null }) => {
    originNodeRef.current = originNodeId;

    const variables = { depth };
    if (id && id.trim() !== "") {
      variables.id = id;
    } else if (name && name.trim() !== "") {
      variables.name = name;
    } else {
      console.error("No valid id or name provided for fetchNodeAndRelationships");
      return;
    }

    getNodeAndRelationships({ variables });
  };

  // Process data when query returns
  useEffect(() => {
    if (data && data.getNodeAndRelationships) {
      const fetchedNodes = data.getNodeAndRelationships.nodes || [];
      const fetchedLinks = data.getNodeAndRelationships.relationships || [];
      const nodeIdMap = {}; // maps top-level id -> properties.id

      const newNodes = fetchedNodes.map((node) => {
        const nodeId = node.properties?.id;
        nodeIdMap[node.id] = nodeId;
        return {
          id: String(nodeId),
          name: node.properties?.name || "Unnamed Node",
          properties: node.properties,
        };
      });

      const newLinks = fetchedLinks.map((rel) => ({
        id: String(rel.id),
        source: nodeIdMap[rel.source],
        target: nodeIdMap[rel.target],
        type: rel.type,
        properties: rel.properties,
      }));

      setGraphData((prevData) => {
        const existingNodes = [...prevData.nodes];
        const existingNodeIds = new Set(existingNodes.map((n) => n.id));
        const existingLinkIds = new Set(prevData.links.map((l) => l.id));

        uniqueNewNodesRef.current = newNodes.filter((n) => !existingNodeIds.has(n.id));
        uniqueNewLinksRef.current = newLinks.filter((l) => !existingLinkIds.has(l.id));

        if (originNodeRef.current) {
          const originNode = existingNodes.find((n) => n.id === originNodeRef.current);
          if (originNode && uniqueNewNodesRef.current.length > 0) {
            const radius = 10;
            uniqueNewNodesRef.current.forEach((node, i) => {
              const angle = (2 * Math.PI * i) / uniqueNewNodesRef.current.length;
              node.x = originNode.x + radius * Math.cos(angle);
              node.y = originNode.y + radius * Math.sin(angle);
            });
          } else if (!originNode && uniqueNewNodesRef.current.length > 0) {
            uniqueNewNodesRef.current.forEach((node) => {
              node.x = 400 + (Math.random() - 0.5) * 100;
              node.y = 300 + (Math.random() - 0.5) * 100;
            });
          }
        }

        const updatedNodes = [...existingNodes, ...uniqueNewNodesRef.current];
        const updatedLinks = [...prevData.links, ...uniqueNewLinksRef.current];

        return { nodes: updatedNodes, links: updatedLinks };
      });
    }
  }, [data, initialNode]);

  useEffect(() => {
    graphDataRef.current = graphData;
  }, [graphData]);

  useEffect(() => {
    if (uniqueNewNodesRef.current.length > 0 || uniqueNewLinksRef.current.length > 0) {
      const rootNode = rootNodeRef.current;
      const currentGraphData = graphDataRef.current;
      const { nodes, links } = currentGraphData;
      const { hierarchy, weight } = computeGraphMetrics(nodes, links, rootNode.id);

      const updatedNodes = graphData.nodes.map((node) => {
        node.properties = {
          ...(node.properties || {}), // Ensure properties is always defined
          weight: weight[node.id] || 0,
          hierarchy: hierarchy[node.id] === Infinity ? null : hierarchy[node.id],
        };
        return node;
      });

      setGraphData((g) => ({ ...g, nodes: updatedNodes }));

      uniqueNewNodesRef.current = [];
      uniqueNewLinksRef.current = [];
    }
  }, [graphData, initialNode]);

  return { graphData, fetchNodeAndRelationships };
};
