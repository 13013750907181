/**
 * Wraps text within a specified width and height, adjusting font size to fit.
 *
 * @param {string} text - The text to wrap.
 * @param {number} width - The maximum width in pixels.
 * @param {string} [font='12px sans-serif'] - The initial font style to use for measurement.
 * @param {number} nodeRadius - The radius of the node (used for height calculation).
 * @param {number} [padding=4] - Optional padding inside the node.
 * @returns {Array<{ text: string, y: number }>} - An array of lines with their y positions.
 */
export const wrapText = (text, width, font = '12px sans-serif', nodeRadius, padding = 4) => {
  if (!text) {
    return []; // Handle empty or undefined text
  }

  const words = text.split(/\s+/);
  let lines = [];
  let fontSize = 12; // Start with a default font size
  let lineHeight = fontSize * 1.2; 
  const minFontSize = 6;
  const maxHeight = nodeRadius * 2 - padding * 2; // Account for padding

  const canvas = wrapText.canvas || (wrapText.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");

  /**
   * Re-wraps the text based on the current font size.
   *
   * @returns {Array<{ text: string, y: number }>}
   */
  const rewrapText = () => {
    lines = [];
    let line = [];
    for (let word of words) {
      // Check if the word itself is too long
      if (context.measureText(word).width > width - padding * 2) {
        // Break the word into smaller chunks
        const brokenWords = breakWord(word, width - padding * 2, context);
        
        for (let brokenWord of brokenWords) { // Replaced forEach with for...of
          line.push(brokenWord);
          const lineText = line.join(' ');
          const textWidth = context.measureText(lineText).width;
          if (textWidth > width - padding * 2 && line.length > 1) {
            line.pop();
            lines.push({ text: line.join(' '), y: lines.length * lineHeight });
            line = [brokenWord];
          }
        }
      } else {
        line.push(word);
        const lineText = line.join(' ');
        const textWidth = context.measureText(lineText).width;
        if (textWidth > width - padding * 2 && line.length > 1) {
          line.pop();
          lines.push({ text: line.join(' '), y: lines.length * lineHeight });
          line = [word];
        }
      }
    }
    if (line.length > 0) {
      lines.push({ text: line.join(' '), y: lines.length * lineHeight });
    }
    return lines;
  };

  /**
   * Breaks a single word into smaller chunks that fit within the specified width.
   *
   * @param {string} word - The word to break.
   * @param {number} maxWidth - The maximum width for each chunk.
   * @param {CanvasRenderingContext2D} ctx - The canvas context for measuring text.
   * @returns {Array<string>} - An array of broken word chunks.
   */
  const breakWord = (word, maxWidth, ctx) => {
    const broken = [];
    let current = '';
    for (let char of word) {
      const test = current + char;
      if (ctx.measureText(test).width > maxWidth) {
        if (current.length === 0) {
          // If a single character is too wide, force to add it to prevent infinite loop
          broken.push(char);
          current = '';
        } else {
          broken.push(current);
          current = char;
        }
      } else {
        current = test;
      }
    }
    if (current.length > 0) {
      broken.push(current);
    }
    return broken;
  };

  // Initial font setup
  context.font = `${fontSize}px sans-serif`;

  // Initial wrapping
  lines = rewrapText();

  // Calculate total text height
  let totalTextHeight = lines.length * lineHeight;

  // Adjust font size to fit both width and height
  while (
    (totalTextHeight > maxHeight || lines.some(line => context.measureText(line.text).width > width - padding * 2)) 
    && fontSize > minFontSize
  ) {
    fontSize--;
    lineHeight = fontSize * 1.2;
    context.font = `${fontSize}px sans-serif`;

    // Recalculate line widths after adjusting font size
    lines = lines.map(line => {
      const metrics = context.measureText(line.text);
      return { ...line, width: metrics.width };
    });

    lines = rewrapText();
    totalTextHeight = lines.length * lineHeight;
  }

  // Final check to ensure all lines fit within width
  if (lines.some(line => context.measureText(line.text).width > width - padding * 2)) {
    // Optionally handle cases where text still doesn't fit
    console.warn('Text does not fit within the node even at minimum font size.');
  }

  // Adjust y positions based on padding
  lines = lines.map(line => ({
    text: line.text,
    y: line.y + padding
  }));

  return lines;
};
