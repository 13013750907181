// src/App.js

import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthContext from './contexts/AuthContext';
import { LoginModal, PrivateRoute, Unauthorized } from './components/Auth';
import Home from './components/Home/Home';
import AdminPage from './components/Admin/AdminPage';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Layout/Header';
import WelcomeMessage from './components/Layout/WelcomeMessage';
import UserAdmin from './components/Admin/UserAdmin';
// import ConceptAdmin from './components/Admin/ConceptAdmin';
import GraphNavigator from './components/GraphNavigator/GraphNavigator';
import AethericAI from './components/AethericAI/AethericAI';
import LandingPage from './components/LandingPage/LandingPage';
import DevAethericAI from './components/AethericAI/dev_AethericAI';



function App() {
  const { logout } = useContext(AuthContext);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleCloseModal = () => {
    setShowLoginModal(false);
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-black text-white">

        {/* Header */}
        <Header onLoginClick={handleLoginClick} onLogoutClick={handleLogoutClick} />

        {/* Welcome Message */}
        <WelcomeMessage />

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center">
          <Routes>
            <Route path="/" element={<LandingPage />} />  {'/landingpage'}
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route 
              path="/landingpage" 
              element={
                <LandingPage />
                } 
            />

            <Route 
              path="/home" 
              element={
                <PrivateRoute allowedRoles={['ADMIN', 'EDITOR', 'PRIVILEGED_USER', 'USER']}>
                  <Home />
                </PrivateRoute>
                } 
            />

            <Route 
              path="/aethericai" 
              element={
                <PrivateRoute allowedRoles={['ADMIN', 'EDITOR', 'PRIVILEGED_USER', 'USER']}>
                  <AethericAI />
                </PrivateRoute>
                } 
            />
            
            <Route 
              path="/dev_aethericai"
              element={
                <PrivateRoute allowedRoles={['ADMIN', 'EDITOR']}>
                  <DevAethericAI />
                </PrivateRoute>
                } 
            />

            <Route 
              path="/graphnavigator" 
              element={
                <PrivateRoute allowedRoles={['ADMIN', 'EDITOR', 'PRIVILEGED_USER']}>
                  <GraphNavigator conceptName='AethericAI' />
                </PrivateRoute>
                } 
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute allowedRoles={['USER', 'ADMIN', 'EDITOR', 'PRIVILEGED_USER']}>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <PrivateRoute allowedRoles={['ADMIN']}>
                  <AdminPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute allowedRoles={['ADMIN']}>
                  <UserAdmin />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/admin/concepts"
              element={
                <PrivateRoute allowedRoles={['ADMIN']}>
                  <ConceptAdmin />
                </PrivateRoute>
              }
            /> */}
            <Route component={Unauthorized} />
          </Routes>
        </main>

        {/* Login Modal */}
        {showLoginModal && <LoginModal onClose={handleCloseModal} />}

      </div>
    </Router>
  );
}

export default App;
