// src/components/GraphNavigator/DataProcessor/nodesUtil.js

/**
 * Finds the ID of the node with the specified concept name in an array of nodes.
 *
 * @param {Array<Object>} nodes - The array of node objects.
 * @param {string} conceptName - The name of the concept to find.
 * @returns {string|null} The ID of the node if found, otherwise null.
 */
export const findNodeID = (nodes, conceptName) => {
    const node = nodes.find(node => node.name === conceptName); // Assuming 'name' property holds the concept name
    return node ? node.id : null; 
  };