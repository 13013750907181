// src/components/UI/Button.js

import React from 'react';

const Button = ({ children, onClick, type = 'button', variant = 'primary', ...props }) => {
  const baseStyles = 'font-bold py-2 px-4 rounded focus:outline-none focus:ring-2';
  const variants = {
    primary: 'bg-black hover:bg-gray-700 text-white focus:ring-gray-500',
    secondary: 'bg-gray-600 hover:bg-gray-700 text-white focus:ring-gray-500',
    success: 'bg-green-500 hover:bg-green-700 text-white focus:ring-green-500',
    danger: 'bg-red-500 hover:bg-red-700 text-white focus:ring-red-500',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseStyles} ${variants[variant]}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
