// src/components/GraphNavigator/initializeSimulation.js

import * as d3 from 'd3';

/**
 * Initializes the D3 force simulation using standard forces with customization.
 *
 * @param {Array<Object>} nodes - The array of node objects.
 * @param {Array<Object>} links - The array of link objects.
 * @param {number} width - The width of the SVG container.
 * @param {number} height - The height of the SVG container.
 * @returns {d3.Simulation} The configured D3 force simulation.
 */
const initializeSimulation = (nodes, links, width, height, rootNodeId) => {
  // Define the custom rebound force within the simulation function
  // const reboundForce = () => {
  //   const margin = 50; // Define margin from the edges

  //   nodes.forEach(node => {
  //     // Apply forces to keep nodes within the boundaries
  //     if (node.x < margin) {
  //       node.vx += 0.1;
  //     }
  //     if (node.x > width - margin) {
  //       node.vx -= 0.1;
  //     }
  //     if (node.y < margin) {
  //       node.vy += 0.1;
  //     }
  //     if (node.y > height - margin) {
  //       node.vy -= 0.1;
  //     }
  //   });
  // };

  // Create the simulation with nodes
  const simulation = d3.forceSimulation(nodes)

    // .force('root', d3.forceManyBody()
    //   .strength(d => d.id === rootNodeId ? 5000 : 0) // Strong attraction for root only
    // )


    // Implement Link Force with strength based on link weight
    .force('link', d3.forceLink(links)
      .id(d => d.id)
      // .distance(200) // Adjust distance as needed for your layout
      // .strength(d => 0.01 * d.weight) // Link strength based on relationship weight
    )

    // Implement Charge Force (forceManyBody) with attraction based on node centrality
    .force('charge', d3.forceManyBody()
      .strength(d => 2 * d.centrality) // Positive for attraction; scale as needed
    )
    // Implement Collision Force to prevent node overlap
    .force('collision', d3.forceCollide()
      .radius(d => 80 * d.weight) // Adjust collision radius based on node weight
      .strength(1)
    )
    // Optional: Implement Centering Force (could be redundant with charge)
    .force('center', d3.forceCenter(width / 2, height / 2))

    // Add custom Rebound Force to keep nodes within boundaries
    // .force('rebound', reboundForce)

    // Optionally, remove or adjust 'x' and 'y' forces if not needed
    // .force('x', d3.forceX(width / 2).strength(0.001))
    // .force('y', d3.forceY(height / 2).strength(0.001))

    // Optional: Adjust simulation parameters for performance
    .alphaDecay(0.05) // Controls the cooling rate
    .alphaTarget(0.01); // Sets the target alpha value

  return simulation;
};

export default initializeSimulation;
