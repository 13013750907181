// src/components/Header.js

import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import AuthContext from '../../contexts/AuthContext';
import Logo from './Logo';
import UserActions from '../UserManagement/UserActions';
import BurgerMenu from './BurgerMenu';

function Header({ onLoginClick, onLogoutClick }) {
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Get current location

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Determine if the current route is Home
  const isHome = location.pathname === '/home';

  return (
    <header className="relative h-16 bg-black grid grid-cols-3 items-center px-4">
      {/* Left Section: Burger Menu */}
      <div className="flex items-center justify-start">
        {user && (
          <button
            onClick={handleMenuToggle}
            className="text-white focus:outline-none"
            aria-label="Toggle menu"
            aria-expanded={isMenuOpen}
          >
            {/* Burger Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        )}
      </div>

      {/* Center Section: Logo */}
      {/* <div className="flex items-center justify-center">
        <Logo />
      </div> */}

      {/* Center Section: Logo (conditionally rendered) */}
       <div className="flex items-center justify-center">
        {!isHome && <Logo />} {/* Render Logo only if not on Home route */}
      </div>

      {/* Right Section: User Actions */}
      <div className="flex items-center justify-end space-x-4">
        <UserActions onLoginClick={onLoginClick} onLogoutClick={onLogoutClick} />
      </div>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={handleMenuToggle}
          ></div>

          {/* Mobile Menu */}
          <BurgerMenu onClose={handleMenuToggle} />
        </>
      )}
    </header>
  );
}

export default Header;