// src/components/GraphNavigator/customMappers.js

/**
 * Custom mapper for nodes to include weight and hierarchy.
 *
 * @param {Object} node - The raw node object from GraphQL.
 * @param {Object} relationshipCounts - A map of node IDs to their relationship counts.
 * @param {Object} centrality - A map of node IDs to their centrality scores.
 * @returns {Object} The mapped node with additional properties.
 */
export const customNodeMapper = (node, relationshipCounts, centrality) => {
    const name = node.properties.name || node.properties.username || 'No Name';
    const type = node.label || 'Unknown';
  
    // Calculate weight based on number of relationships
    const weight = relationshipCounts[node.id] || 1;
  
    // Calculate hierarchy based on centrality
    const hierarchy = centrality[node.id] || 0;
  
    return {
      ...node,
      name,
      type,
      weight,
      hierarchy,
    };
  };
  
  /**
   * Custom mapper for links. Modify as needed.
   *
   * @param {Object} link - The raw relationship object from GraphQL.
   * @returns {Object} The mapped link.
   */
  export const customLinkMapper = (link) => {
    const distance = link.distance || 1; // Ensure distance is defined
    const weight = 1 / distance;
  
    return {
      ...link,
      source: link.source,
      target: link.target,
      type: link.type,
      properties: link.properties,
      distance,
      weight,
    };
  };
  