// src/components/UI/Input.js

import React from 'react';

// Using React.forwardRef to allow ref forwarding
const Input = React.forwardRef(
  (
    {
      label,
      name,
      type = 'text',
      value, // Controlled input value
      onChange, // Controlled input onChange handler
      required = false,
      error, // For displaying validation errors
      ...props // Capture any additional props, including ref
    },
    ref
  ) => (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-300 mb-1">
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type={type}
        value={value} // Controlled input value
        onChange={onChange} // Controlled input onChange handler
        required={required}
        ref={ref} // Attach the forwarded ref
        className={`w-full px-3 py-2 bg-gray-700 border ${
          error ? 'border-red-500' : 'border-gray-600'
        } rounded focus:outline-none focus:ring-2 focus:ring-blue-500`}
        {...props} // Spread any additional props
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  )
);

// Set a display name for better debugging in React DevTools
Input.displayName = 'Input';

export default Input;