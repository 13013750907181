// src/components/GraphNavigator/initializeZoom.js

import * as d3 from 'd3';

const initializeZoom = (svgElement, svg) => {
  const zoom = d3
    .zoom()
    .scaleExtent([0.1, 4])
    .on('zoom', (event) => {
      svg.attr('transform', event.transform);
    });

  svgElement.call(zoom);

  return zoom;
};

export default initializeZoom;
