// src/components/AethericAI/DevGraphNavigator/graph/initializeSVG.js

import * as d3 from "d3";

export function initializeSVG(svgRef, svg) {
  const svgGroup = svg.append("g").attr("class", "graph-group");
  svgGroup.append("g").attr("class", "links-layer");
  svgGroup.append("g").attr("class", "nodes-layer");

  const zoom = d3.zoom()
    .scaleExtent([0.1, 4])
    .on("zoom", (event) => {
      svgGroup.attr("transform", event.transform);
    });

  svg.call(zoom);
  svgRef.current = zoom;

  // Disable double-click zooming on the SVG
  svg.on("dblclick.zoom", null);
}
