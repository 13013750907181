import * as d3 from "d3";
import { wrapText } from "../wrapText";
import { getNodeWeight } from "./utils/getNodeWeight";

export function updateNodes({
  nodesLayer,
  nodesData,
  initialNodeId,
  rootNodeId,
  selectedNodeIds,
  panelOpenNodeId,
  onClick,
  onDoubleClick,
  simulationRef
}) {
  const node = nodesLayer.selectAll(".node").data(nodesData, (d) => d.id);

  // Exit old nodes
  node.exit().remove();

  console.log('rootNodeId', rootNodeId);

  const nodeEnter = node
    .enter()
    .append("g")
    .attr("class", "node")
    .on("click", (event, d) => onClick(d))
    .on("dblclick", (event, d) => onDoubleClick(d))

    // **Apply drag only to nodes that are NOT the initial node and NOT the root node**
    const nodeMerged = nodeEnter.merge(node);

    // Remove drag handlers from all nodes first
    nodeMerged.on(".drag", null); 

    const draggableNodes = nodeMerged.filter((d) => d.id !== rootNodeId && d.id !== initialNodeId);
    console.log('draggableNodes', draggableNodes);

    draggableNodes.call(
      d3.drag()
        .on("start", (event, d) => {
          if (!simulationRef.current) return;
          // Neither initial node nor root node should be draggable
          if (d.id === rootNodeId || d.id === initialNodeId) 
          {console.log('d.id', d.id); 
            console.log('rootNodeId', rootNodeId);
            console.log('initialNodeId', initialNodeId);
            return;}

          
          if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;
        })
        .on("drag", (event, d) => {
          if (d.id === rootNodeId || d.id === initialNodeId) return; // not draggable
          d.fx = event.x;
          d.fy = event.y;
        })
        .on("end", (event, d) => {
          if (!simulationRef.current) return;
          if (!event.active) simulationRef.current.alphaTarget(0.01);
          if (d.id !== rootNodeId && d.id !== initialNodeId) {
            d.fx = d.x;
            d.fy = d.y;
          }
        })
    );

  // Append invisible circles
  nodeEnter
    .append("circle")
    .attr("class", "invisible-circle")
    .attr("r", (d) => {
      const w = getNodeWeight(d);
      if (d.id === initialNodeId) return 60;
      return d.id === rootNodeId ? 50 : 40 + 30 / w;
    })
    .attr("fill", "transparent")
    .on("click", (event, d) => onClick(d))
    .on("dblclick", (event, d) => onDoubleClick(d));

  // Append visible circles
  nodeEnter
    .append("circle")
    .attr("class", "visible-circle")
    .attr("r", (d) => d.id === initialNodeId ? 60 : d.id === rootNodeId ? 50 : 40)
    .attr("fill", (d) => {
      if (d.id === initialNodeId) return "black";     // Initial node always black
      if (d.id === rootNodeId) return "#2c3e50";      // Root node different color
      return "black";                                 // Other nodes black
    })
    .attr("stroke", (d) => {
      if (selectedNodeIds.includes(d.id)) return "yellow";
      if (d.id === initialNodeId) return "white" // Initial node stroke
      if (d.id === rootNodeId) return "#f39c12";      // Root node stroke
      return "white";
    })
    .attr("stroke-width", (d) =>
      panelOpenNodeId === d.id ? 4 : (d.id === initialNodeId || d.id === rootNodeId ? 3 : 2)
    );

  // Append text labels
  nodeEnter.each(function (d) {
    const nodeElement = d3.select(this);
    const radius = d.id === initialNodeId ? 60 : d.id === rootNodeId ? 50 : 40;
    const maxTextWidth = radius * 2 - 8;
    const { lines, fontSize, lineHeight } = wrapText(
      d.name,
      maxTextWidth,
      "12px sans-serif",
      radius
    );

    const textElement = nodeElement
      .append("text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "#ffffff")
      .style("font-size", `${fontSize}px`);

    const totalHeight = lines.length * lineHeight;
    const startY = -totalHeight / 2 + lineHeight / 2;

    lines.forEach((line) => {
      textElement
        .append("tspan")
        .attr("x", 0)
        .attr("y", startY + line.y)
        .text(line.text);
    });
  });

  // Merge and update node styles
  nodeEnter
    .merge(node)
    .select(".visible-circle")
    .attr("stroke", (d) => {
      if (selectedNodeIds.includes(d.id)) return "yellow";
      if (d.id === initialNodeId) return "white";
      if (d.id === rootNodeId) return "#f39c12";
      return "white";
    })
    .attr("stroke-width", (d) =>
      panelOpenNodeId === d.id ? 4 : ((d.id === initialNodeId || d.id === rootNodeId) ? 3 : 2)
    );
}
