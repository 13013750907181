// src/components/UserComponents/UpdateUser.js

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_MUTATION } from '../../graphql/User/user_mutations';
import UserForm from './UserForm';

const UpdateUser = ({ selectedUser, setActiveView, refreshUserList }) => {
  const [formState, setFormState] = useState({
    username: selectedUser.username || '',
    email: selectedUser.email || '',
    role: selectedUser.role || 'USER',
    status: selectedUser.status || 'ACTIVE',
    password: '',
  });
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    let variables = { id: selectedUser.id };

    if (formState.username !== selectedUser.username) {
      variables.username = formState.username;
    }
    if (formState.role !== selectedUser.role) {
      variables.role = formState.role;
    }
    if (formState.status !== selectedUser.status) {
      variables.status = formState.status;
    }
    if (formState.password) {
      variables.password = formState.password;
    }

    if (Object.keys(variables).length === 1) {
      setError('No changes made.');
      setLoading(false);
      return;
    }

    try {
      await updateUser({ variables });
      setActiveView('list');
      refreshUserList();
    } catch (err) {
      console.error('Error updating user:', err);
      setError(
        err.graphQLErrors?.[0]?.message ||
          err.networkError?.message ||
          'Failed to update user. Please check your inputs.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Edit User</h2>
      <UserForm
        formState={formState}
        isEditing={true}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setActiveView={setActiveView}
      />
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {loading && <p>Updating user...</p>}
    </div>
  );
};

export default UpdateUser;