// src/components/UserComponents/CreateUser.js

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from '../../graphql/User/user_mutations';
import UserForm from './UserForm';

const CreateUser = ({ setActiveView, refreshUserList }) => {
  const [formState, setFormState] = useState({
    username: '',
    email: '',
    password: '',
    role: 'USER',
    status: 'ACTIVE',
  });
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const variables = {
      username: formState.username,
      email: formState.email.toLowerCase(),
      password: formState.password,
      role: formState.role,
      status: formState.status,
    };

    if (
      !variables.username ||
      !variables.email ||
      !variables.password ||
      !variables.role ||
      !variables.status
    ) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    try {
      await createUser({ variables });
      setActiveView('list');
      refreshUserList();
    } catch (err) {
      console.error('Error creating user:', err);
      setError(
        err.graphQLErrors?.[0]?.message ||
          err.networkError?.message ||
          'Failed to create user. Please check your inputs.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Add New User</h2>
      <UserForm
        formState={formState}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isEditing={false}
        setActiveView={setActiveView}
      />
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {loading && <p>Creating user...</p>}
    </div>
  );
};

export default CreateUser;