// src/components/GraphNavigator/initializeSvg.js

import * as d3 from 'd3';

const initializeSvg = (svgRef) => {
  const svgElement = d3.select(svgRef.current);
  svgElement.selectAll('*').remove(); // Clear previous content

  // Get the actual size of the container
  const container = svgElement.node().parentNode;
  const width = container.clientWidth;
  const height = container.clientHeight;

  // Set SVG attributes
  svgElement
    .attr('width', width)
    .attr('height', height);

  const svg = svgElement.append('g').attr('class', 'container');

  return { svgElement, svg, width, height };
};

export default initializeSvg;
