// src/components/GraphNavigator/GraphNavigator.js
import React, { useRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CONCEPT_AND_RELATIONSHIPS } from '../../graphql/Concept/concept_queries';
import useD3Graph from './useD3Graph';
import { processDataForGraph } from './DataProcessor/graphDataProcessor';
import { customNodeMapper, customLinkMapper } from './customMappers';
import { findNodeID } from './DataProcessor/nodesUtil';

const GraphNavigator = ({ conceptName }: { conceptName: String}) => {
  const { loading, error, data } = useQuery(GET_CONCEPT_AND_RELATIONSHIPS, {
    variables: { conceptName: conceptName, depth: 10 },
  });

  const svgRef = useRef();

  // Prepare the graph data using the enhanced processDataForGraph with custom mappers
  const graphData = useMemo(() => {
    if (!data || !data.getConceptAndRelationships) return { nodes: [], links: [] };

    const { nodes, relationships } = data.getConceptAndRelationships;

    return processDataForGraph(
      { nodes, relationships },
      {
        nodeNameField: 'name',   // Adjust based on your node properties
        nodeTypeField: 'label',  // Adjust based on your node properties
        customNodeMapper: (node, counts, centrality) =>
          customNodeMapper(node, counts, centrality),
        customLinkMapper: (rel) => customLinkMapper(rel),
      }
    );
  }, [data]);

  //

  const rootNodeId = findNodeID(graphData.nodes, conceptName);

  // Use the custom D3 hook with the processed graph data and rootNodeId
  useD3Graph(svgRef, graphData, loading, error, rootNodeId);

  return (
    <div>
      {loading && <p>Loading concepts...</p>}
      {error && <p>Error loading concepts: {error.message}</p>}
      <svg ref={svgRef} className="w-screen h-screen"></svg>
    </div>
  );
};

export default GraphNavigator;
