// src/components/AethericAI/AethericAI.js

import React from 'react';
import NewGraphNavigator from './GraphNavigator/NewGraphNavigator';
import logo from '../../assets/images/AethericAI.png';

const AethericAI = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
        <NewGraphNavigator/>
    </div>
  );
};

export default AethericAI;
