// src/graphql/Graph/graph_queries.js
import { gql } from '@apollo/client';

export const GET_NODE_AND_RELATIONSHIPS = gql`
  query GetNodeAndRelationships($id: ID, $name: String, $depth: Int = 1) {
    getNodeAndRelationships(id: $id, name: $name, depth: $depth) {
      nodes {
        id
        label
        description
        tags
        privacy_status
        properties
      }
      relationships {
        id
        type
        source
        target
        properties
      }
    }
  }
`;

export const GET_GRAPH = gql`
  query GetGraph {
    getGraph {
      nodes {
        id
        label
        description
        tags
        privacy_status
        properties
      }
      relationships {
        id
        type
        source
        target
        properties
      }
    }
  }
`;
