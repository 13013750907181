import React from 'react';
import UserAdmin from './UserAdmin';
// import ConceptAdmin from './ConceptAdmin';

const AdminPage = () => {
  return (
    <div className="container mx-auto p-6 overflow-hidden">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {/* UserAdmin Module */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <UserAdmin />
        </div>
        {/* <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
          <ConceptAdmin />
        </div> */}
      </div>
    </div>
  );
};

export default AdminPage;