// src/components/UserComponents/UserTable.js

import React from 'react';
import { Button } from '../UI_temp';

const UserTable = ({ users, handleEdit, handleDelete }) => (
  <table className="min-w-full bg-gray-800 text-white">
    <thead>
      <tr>
        <th className="py-2 px-4 border-b border-gray-700">Username</th>
        <th className="py-2 px-4 border-b border-gray-700">Email</th>
        <th className="py-2 px-4 border-b border-gray-700">Role</th>
        <th className="py-2 px-4 border-b border-gray-700">Status</th>
        <th className="py-2 px-4 border-b border-gray-700">Actions</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user.id}>
          <td className="py-2 px-4 border-b border-gray-700">{user.username}</td>
          <td className="py-2 px-4 border-b border-gray-700">{user.email}</td>
          <td className="py-2 px-4 border-b border-gray-700">{user.role}</td>
          <td className="py-2 px-4 border-b border-gray-700">{user.status}</td>
          <td className="py-2 px-4 border-b border-gray-700">
            <Button
              variant="primary"
              onClick={() => handleEdit(user)}
              className="mr-2 py-1 px-2"
            >
              Edit
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDelete(user.id)}
              className="py-1 px-2"
            >
              Delete
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default UserTable;
