// src/graphql/User/user_mutations.js

import { gql } from '@apollo/client';

// Fragment to reuse user fields
export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    username
    email
    role
    status
    createdAt
    updatedAt
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation SignUp(
    $username: String
    $email: String!
    $password: String!
    $referredById: ID
  ) {
    signUp(
      username: $username
      email: $email
      password: $password
      referredById: $referredById
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $username: String
    $email: String!
    $password: String
    $referredById: ID
  ) {
    createUser(
      username: $username
      email: $email
      password: $password
      referredById: $referredById
    ) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!
    $username: String
    $email: String
    $password: String
    $role: Role
    $status: Status
  ) {
    updateUser(id: $id, username: $username, email: $email, password: $password, role: $role, status: $status) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
      message
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`;
