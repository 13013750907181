// src/graphql/Graph/graph_mutations.js
import { gql } from '@apollo/client';

export const CREATE_NODE = gql`
  mutation CreateNode($input: NodeInput!) {
    createNode(input: $input) {
      id
      label
      description
      tags
      privacy_status
      properties
    }
  }
`;

export const CREATE_RELATIONSHIP = gql`
  mutation CreateRelationship($input: RelationshipInput!) {
    createRelationship(input: $input) {
      id
      type
      source
      target
      properties
    }
  }
`;
