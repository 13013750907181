// src/components/AethericAI/dev_AethericAI.js

import React from 'react';
import NewGraphNavigator from './DevGraphNavigator/NewGraphNavigator';
import logo from '../../assets/images/AethericAI.png';

const DevAethericAI = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
        <NewGraphNavigator conceptName='AethericAI' />
    </div>
  );
};

export default DevAethericAI;
