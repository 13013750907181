// src/components/GraphNavigator/useD3Graph.js

import { useEffect } from 'react';
import initializeSvg from './initializeSvg';
import initializeZoom from './initializeZoom';
import initializeSimulation from './initializeSimulation';
import renderLinks from './renderLinks';
import renderNodes from './renderNodes';
import setupTooltip from './tooltip';
import dragHandlers from './dragHandlers';
import * as d3 from 'd3';
import { createHierarchy, computeInitialPositions, fixRootNode } from './DataProcessor/hierarchyHelpers';

const useD3Graph = (svgRef, graphData, loading, error, rootNodeId) => {
  useEffect(() => {
    if (loading || error) return;
    if (!graphData.nodes.length) return;

    const { svgElement, svg, width, height } = initializeSvg(svgRef);

    // Initialize zoom
    initializeZoom(svgElement, svg);

    // Create hierarchy and compute initial positions
    const root = createHierarchy(graphData.nodes, graphData.links, rootNodeId);
    const initialNodes = computeInitialPositions(root, width, height);

    // Fix root node position
    fixRootNode(initialNodes, width, height, rootNodeId);

    // Deep copy links
    const links = graphData.links.map(link => ({
      ...link,
      source: link.source,
      target: link.target,
    }));

    // Initialize the simulation with fixed root node
    const simulation = initializeSimulation(initialNodes, links, width, height, rootNodeId);

    const { dragstarted, dragged, dragended } = dragHandlers(simulation, rootNodeId, width, height);

    const link = renderLinks(svg, links);

    const node = renderNodes(svg, initialNodes, dragstarted, dragged, dragended, rootNodeId);

    const tooltip = setupTooltip();

    node
      .on('mouseover', (event, d) => {
        tooltip.show(event, d);
      })
      .on('mouseout', () => {
        tooltip.hide();
      });

    simulation.on('tick', () => {
      // Update node positions
      node.attr('transform', d => `translate(${d.x},${d.y})`);

      // Update link positions
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
    });

    // Handle window resize
    const handleResize = () => {
      const container = svgElement.node().parentNode;
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;

      svgElement
        .attr('width', newWidth)
        .attr('height', newHeight);

      simulation
        .force('center', d3.forceCenter(newWidth / 2, newHeight / 2))
        .alpha(0.5)
        .restart();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      simulation.stop();
      svgElement.selectAll('*').remove();
      tooltip.remove();
      window.removeEventListener('resize', handleResize);
    };
  }, [svgRef, graphData, loading, error, rootNodeId]);
};

export default useD3Graph;
