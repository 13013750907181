// src/components/GraphNavigator/tooltip.js

import * as d3 from 'd3';

const setupTooltip = () => {
  const tooltip = d3.select('body').append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('text-align', 'left')
    .style('width', 'auto')
    .style('height', 'auto')
    .style('padding', '8px')
    .style('font', '12px sans-serif')
    .style('background', 'rgba(0, 0, 0, 0.6)')
    .style('color', '#fff')
    .style('border-radius', '4px')
    .style('pointer-events', 'none')
    .style('opacity', 0);

  const show = (event, d, type = 'node') => {
    let htmlContent = '';

    if (type === 'node') {
      htmlContent = `<strong>${d.name}</strong><br/>Type: ${d.type}<br/>Weight: ${d.weight}<br/>Hierarchy: ${d.hierarchy} <br/>centrality: ${d.centrality}`;
    } else if (type === 'relationship') {
      htmlContent = `<strong>Relationship</strong><br/>Type: ${d.type}<br/>Weight: ${d.weight.toFixed(2)}<br/>Distance: ${d.distance}`;
    }

    tooltip
      .html(htmlContent)
      .style('left', (event.pageX + 10) + 'px')
      .style('top', (event.pageY + 10) + 'px')
      .transition()
      .duration(200)
      .style('opacity', 0.9);
  };

  const hide = () => {
    tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  };

  const remove = () => {
    tooltip.remove();
  }

  return { show, hide, remove };
};

export default setupTooltip;
