// src/components/AethericAI/DevGraphNavigator/graph/ticked.js

import * as d3 from "d3";

export function ticked(svgRef) {
  if (!svgRef.current) return;
  const svg = d3.select(svgRef.current);
  const svgGroup = svg.select(".graph-group");
  const linksLayer = svgGroup.select(".links-layer");
  const nodesLayer = svgGroup.select(".nodes-layer");

  // Update node positions
  nodesLayer
    .selectAll(".node")
    .attr("transform", (d) => `translate(${d.x},${d.y})`);

  // Update link positions
  linksLayer
    .selectAll(".link")
    .attr("x1", (d) => d.source.x)
    .attr("y1", (d) => d.source.y)
    .attr("x2", (d) => d.target.x)
    .attr("y2", (d) => d.target.y);
}
