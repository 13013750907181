// src/components/WelcomeMessage.js

import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';

const WelcomeMessage = () => {
  const { user } = useContext(AuthContext);

  if (!user) return null; // Don't display if no user is logged in

  return (
    <div className="w-full bg-black text-white p-2">
      <h2 className="text-xl">Welcome {user.username}</h2>
    </div>
  );
};

export default WelcomeMessage;