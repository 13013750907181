import { useRef, useEffect, useCallback, useState } from "react";
import * as d3 from "d3";
import { initializeSVG } from "./graph/initializeSVG";
import { updateLinks } from "./graph/updateLinks";
import { updateNodes } from "./graph/updateNodes";
import { initializeOrUpdateSimulation } from "./graph/initializeSimulation";
import { ticked } from "./graph/ticked";
import { zoomToNode } from "./graph/zoomToNode";

export const useD3Graph = ({
  graphData,
  width = 800,
  height = 600,
  initialNode,     // The node that never changes, always at center (and black)
  rootNode,         // The node that is currently the root (fixed at current place)
  selectedNodes = [],
  panelOpenNodeId,
  onNodeClick,
  onNodeDoubleClick,
}) => {
  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const simulationRef = useRef(null);

  // Keep track of the previous root node ID to know when the root changes
  const previousRootIdRef = useRef(rootNode.id);

  // Store the initial node ID (never changes)
  const [initialNodeId] = useState(initialNode.id);

  const tickedCallback = useCallback(() => {
    ticked(svgRef);
  }, []);

  useEffect(() => {
    if (!svgRef.current || !graphData) return;
    const svg = d3.select(svgRef.current);

    // Determine actual dimensions of the SVG container
    let measuredWidth = width;
    let measuredHeight = height;
    if (svgRef.current) {
      const rect = svgRef.current.getBoundingClientRect();
      measuredWidth = rect.width;
      measuredHeight = rect.height;
    }

    // Initialize SVG elements once
    if (!zoomRef.current) {
      initializeSVG(zoomRef, svg);
    }

    const svgGroup = svg.select(".graph-group");
    const linksLayer = svgGroup.select(".links-layer");
    const nodesLayer = svgGroup.select(".nodes-layer");

    // Update links and nodes
    updateLinks(linksLayer, graphData.links);
    updateNodes({
      nodesLayer,
      nodesData: graphData.nodes,
      initialNodeId,
      rootNodeId: rootNode.id,
      selectedNodeIds: selectedNodes.map((node) => node.id),
      panelOpenNodeId,
      onClick: onNodeClick,
      onDoubleClick: onNodeDoubleClick,
      simulationRef
    });

    // Initialize or update the simulation and handle root transitions
    initializeOrUpdateSimulation({
      simulationRef,
      nodes: graphData.nodes,
      links: graphData.links,
      rootId: rootNode.id,
      initialNodeId,
      previousRootIdRef,
      ticked: tickedCallback,
      measuredWidth,
      measuredHeight
    });

    // Cleanup on unmount
    return () => {
      if (simulationRef.current) simulationRef.current.stop();
    };
  }, [
    graphData,
    rootNode.id,
    selectedNodes,
    panelOpenNodeId,
    onNodeClick,
    onNodeDoubleClick,
    width,
    height,
    tickedCallback,
    initialNodeId
  ]);

  const zoomToNodeCallback = useCallback(
    (nodeId, scaleFactor) => {
      zoomToNode({ svgRef, graphData, nodeId, width, height, scaleFactor });
    },
    [graphData, width, height]
  );

  return { svgRef, zoomToNode: zoomToNodeCallback };
};
