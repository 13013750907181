// src/components/UserComponents/UserForm.js

import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';

const UserActions = ({ onLoginClick, onLogoutClick }) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="flex items-center space-x-4">
      {user ? (
        <>
          <button
            onClick={onLogoutClick}
            className="bg-black text-white px-3 py-1 rounded hover:bg-gray-800 transition"
          >
            Logout
          </button>
        </>
      ) : (
        <button
          onClick={onLoginClick}
          className="bg-black text-white px-3 py-1 rounded hover:bg-gray-800 transition"
        >
          Login
        </button>
      )}
    </div>
  );
};

export default UserActions;