// src/graphql/Concept/concept_queries.js

import { gql } from '@apollo/client';

export const GET_CONCEPT_AND_RELATIONSHIPS = gql`
  query GetConceptAndRelationships($conceptName: String!, $depth: Int) {
    getConceptAndRelationships(conceptName: $conceptName, depth: $depth) {
      nodes {
        id
        label
        properties
      }
      relationships {
        id
        type
        source
        target
        properties
      }
    }
  }
`;

// export const GET_CONCEPT = gql`
//   query GetConcept($id: ID!) {
//     getConcept(id: $id) {
//       nodes {
//         id
//         label
//         properties
//       }
//       relationships {
//         id
//         type
//         source
//         target
//         properties
//       }
//     }
//   }
// `;

// export const EXPAND_CONCEPT = gql`
//   query ExpandConcept($id: ID!, $depth: Int) {
//     expandConcept(id: $id, depth: $depth) {
//       nodes {
//         id
//         label
//         properties
//       }
//       relationships {
//         id
//         type
//         source
//         target
//         properties
//       }
//     }
//   }
// `;

// export const LIST_CONCEPTS = gql`
//   query ListConcepts($skip: Int, $limit: Int) {
//     listConcepts(skip: $skip, limit: $limit) {
//       nodes {
//         id
//         label
//         properties
//       }
//       relationships {
//         id
//         type
//         source
//         target
//         properties
//       }
//     }
//   }
// `;

// export const GET_CONCEPT_BY_NAME = gql`
//   query GetConceptByName($name: String!) {
//     getConceptByName(name: $name) {
//       nodes {
//         id
//         label
//         properties
//       }
//       relationships {
//         id
//         type
//         source
//         target
//         properties
//       }
//     }
//   }
// `;