// src/components/UserComponents/UserForm.js

import React from 'react';
import { Button, Input, Select } from '../UI_temp';

const UserForm = ({
  formState,
  handleChange,
  handleSubmit,
  isEditing,
  loading,
  error,
  onCancel,
}) => (
  <form onSubmit={handleSubmit} className="space-y-4">
    <Input
      label="Username"
      name="username"
      value={formState.username}
      onChange={handleChange}
      required
    />
    {!isEditing && (
      <Input
        label="Email"
        name="email"
        type="email"
        value={formState.email}
        onChange={handleChange}
        required
      />
    )}
    {isEditing && (
      <Input
        label="Email"
        name="email"
        type="email"
        value={formState.email}
        onChange={handleChange}
        required
        disabled
      />
    )}
    <Select
      label="Role"
      name="role"
      value={formState.role}
      onChange={handleChange}
      required
      options={[
        { value: '', label: 'Select Role' },
        { value: 'USER', label: 'USER' },
        { value: 'ADMIN', label: 'ADMIN' },
        { value: 'EDITOR', label: 'EDITOR' },
        { value: 'PRIVILEGED_USER', label: 'PRIVILEGED_USER' },
      ]}
    />
    <Select
      label="Status"
      name="status"
      value={formState.status}
      onChange={handleChange}
      required
      options={[
        { value: '', label: 'Select Status' },
        { value: 'ACTIVE', label: 'ACTIVE' },
        { value: 'PENDING_VALIDATION', label: 'PENDING_VALIDATION' },
        { value: 'DEACTIVATED', label: 'DEACTIVATED' },
      ]}
    />
    {!isEditing && (
      <Input
        label="Password"
        name="password"
        type="password"
        value={formState.password}
        onChange={handleChange}
        required
      />
    )}
    {isEditing && (
      <Input
        label="New Password (optional)"
        name="password"
        type="password"
        value={formState.password}
        onChange={handleChange}
      />
    )}
    {error && <p className="text-red-500">{error.message}</p>}
    <div className="flex justify-end space-x-2">
      {onCancel && (
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      )}
      <Button type="submit" variant="primary" disabled={loading}>
        {isEditing ? 'Update' : 'Create'}
      </Button>
    </div>
  </form>
);

export default UserForm;
