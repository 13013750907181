// src/contexts/InitialRootContext.js

import React, { createContext } from "react";

const InitialRootContext = createContext(null);

export const InitialRootProvider = ({ children, initialRoot }) => (
  <InitialRootContext.Provider value={initialRoot}>
    {children}
  </InitialRootContext.Provider>
);

export default InitialRootContext;
