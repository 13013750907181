// src/components/GraphNavigator/renderLinks.js

import * as d3 from 'd3';

const renderLinks = (svg, links) => {
  // Determine maximum weight for scaling
  const maxWeight = d3.max(links, (d) => d.weight) || 1;

  const link = svg
    .selectAll('.link')
    .data(links)
    .enter()
    .append('line')
    .attr('class', 'link')
    .attr('stroke-width', (d) => 1 + (d.weight / maxWeight) * 4) // Adjust thickness
    .attr('stroke', (d) => d3.interpolateInferno(d.weight / maxWeight)) // Adjust color based on weight
    .attr('stroke-opacity', 0.1); // Optional: Add some opacity

  const linkLabels = svg
    .selectAll('.link-label')
    .data(links)
    .enter()
    .append('text')
    .attr('class', 'link-label')
    .attr('font-size', '10px')
    .attr('fill', '#555')
    .attr('text-anchor', 'middle')
    .text((d) => d.weight.toFixed(2));

  return link;
};

export default renderLinks;
