// src/components/Logo.js

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo150px.png'; // Adjust the path to your logo

const Logo = () => {
  return (
    <Link to="/">
      <img src={logo} alt="Logo" className="h-14" />
    </Link>
  );
};

export default Logo;