// /

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/globals.css'; // Ensure this path is correct
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/Tools/ErrorBoundary';

// Import ApolloProvider and the configured Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';

// Import AuthProvider for authentication context (we'll create this later)
import { AuthProvider } from './contexts/AuthContext';

import AuthLoader from './components/Auth/AuthLoader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <AuthProvider>
          <AuthLoader>
            <App />
          </AuthLoader>
        </AuthProvider>
      </ErrorBoundary>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
